<template>
  <div style="overflow: hidden" class="activity-mother">
    <control-navbar/>
    <page-header :title="parent.label" v-if="parent"/>
    <br/>
    <div class="container activity">
      <!-- date picker -->
      <div class="row d-flex flex-row date-picker align-items-center">
        <div class="col-5 align-start">
          <a-date-picker
              placeholder="From"
              v-model="from"
              @change="changeFromDate"
          />
        </div>
        <div class="col-2">
          <a-icon type="calendar" class="calendar"/>
        </div>
        <div class="col-5 align-end">
          <a-date-picker placeholder="To" v-model="to" @change="changeToDate"/>
        </div>
      </div>
      <!-- end date picker -->
      <br/>
      <div class="row">
        <div class="col-12 align-start">
          <a-radio-group name="radioGroup" @change="fetch" v-model="device">
            <a-radio :value="false"> current device</a-radio>
            <a-radio :value="true"> all devices</a-radio>
          </a-radio-group>
        </div>
      </div>
      <br/>
      <div class="row">
        <a-table
            :columns="columns"
            :row-key="(data) => data.key"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
        >
          <template slot="description" slot-scope="description">
            <div @click="checkDetails(description.ref, description.description, description.date)">
              <span class="date">
                {{ description.date }}
              </span>

              <br/>
              {{ description.description }}
            </div>
          </template>

          <template slot="inout" slot-scope="inout">
            <div @click="checkDetails(inout.ref, inout.description, inout.date)">
              {{ inout.balance }}<br/>
              <span
                  :style="`color: ${
                  inout.style.color || 'inherit'
                }; font-family:${inout.style.font || 'inherit'}; font-size: ${
                  inout.style.fontSize || 'initial'
                }; text-transform: ${
                  inout.style.fontCase === 'normal'
                    ? 'inherit'
                    : inout.style.fontCase
                }; ${
                  inout.style.strikethrough
                    ? 'text-decoration:line-through'
                    : ''
                };${inout.style.underline ? 'text-decoration:underline' : ''};${
                  inout.style.italic ? 'font-style: italic' : ''
                };${inout.style.bold ? 'font-weight: bold' : ''}; `"
              >{{
                  inout.amount
                      | numeral($session.get("currencyFormat").replace("#", 0))
                }}
                {{ $session.get("currency") }}
              </span>
            </div>
          </template>

          <template slot="catser" slot-scope="catser">
            <div @click="checkDetails(catser.ref, catser.description, catser.date)">
              {{ catser.text == "null" ? "-" : catser.text }}
            </div>
          </template>
        </a-table>
      </div>
      <br/>
    </div>
    <a-modal
        v-if="itemDetailsObject"
        v-model="itemDetailsVisible"
        title=""
        :footer="null"
    >
      <div class="row d-flex align-items-center">
        <div class="col-2">
          <img ref="details_image"
               :src="require('../assets/default-placeholder.png')"
               style="width: inherit;"
               alt="">
        </div>
        <div class="col-5"><span>{{ detailsHeader.title }}</span></div>
        <div class="col-5"><span class="date">{{ detailsHeader.date }}</span></div>
      </div>
      <div
          style="padding: 15px"
          v-for="(item, index) in itemDetailsObject"
          :key="index"
      >
        <p v-for="(entry, ei) in item.entries" :key="ei + 'i'">
          <span
              :style="`color: #${entry.entry.labelViewStyle.color}; font-family:${
              entry.entry.labelViewStyle.font || 'inherit'
            }; font-size: ${
              entry.entry.labelViewStyle.fontSize || 'initial'
            }; text-transform: ${
              entry.entry.labelViewStyle.fontCase === 'normal'
                ? 'inherit'
                : entry.entry.labelViewStyle.fontCase
            }; ${
              entry.entry.labelViewStyle.strikethrough
                ? 'text-decoration:line-through'
                : ''
            };${
              entry.entry.labelViewStyle.underline
                ? 'text-decoration:underline'
                : ''
            };${
              entry.entry.labelViewStyle.italic ? 'font-style: italic' : ''
            };${entry.entry.labelViewStyle.bold ? 'font-weight: bold' : ''}; `"
          >
            {{ entry.entry.label }}
          </span>
          <span
              :style="`color: #${entry.entry.valueViewStyle.color}; font-family:${
              entry.entry.valueViewStyle.font || 'inherit'
            }; font-size: ${
              entry.entry.valueViewStyle.fontSize || 'initial'
            }; text-transform: ${
              entry.entry.valueViewStyle.fontCase === 'normal'
                ? 'inherit'
                : entry.entry.valueViewStyle.fontCase
            }; ${
              entry.entry.valueViewStyle.strikethrough
                ? 'text-decoration:line-through'
                : ''
            };${
              entry.entry.valueViewStyle.underline
                ? 'text-decoration:underline'
                : ''
            };${
              entry.entry.valueViewStyle.italic ? 'font-style: italic' : ''
            };${entry.entry.valueViewStyle.bold ? 'font-weight: bold' : ''}; `"
          >
            {{ entry.value }}
          </span>
        </p>
        <hr/>
      </div>
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ControlNavbar from "../components/ControlNavbar.vue";
import {LocaleArabic, LocaleEnglish} from "@/scripts/Locale";
import moment from "moment";
import axios from "axios";

export default {
  name: "Activity",
  components: {
    ControlNavbar,
  },
  data() {
    return {
      detailsHeader: null,
      loader: false,
      itemDetailsObject: null,
      itemDetailsVisible: false,
      device: false,
      from: moment().add(-7, "days"),
      to: moment(),
      data: [],
      pagination: {},
      loading: false,
      parent: null,
      locale: null,
      columns: [
        {
          title: "Description",
          dataIndex: "description",
          sorter: (a, b) =>
              Number(b.description.dateInt) - Number(a.description.dateInt),
          scopedSlots: {customRender: "description"},
        },
        {
          title: "In/Out",
          dataIndex: "inout",
          scopedSlots: {customRender: "inout"},
          filterMultiple: false,
          onFilter: (value, record) => record.address.indexOf(value) === 0,
          sorter: (a, b) => Number(b.inout.amount) - Number(a.inout.amount),
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Category/Service",
          dataIndex: "catser",
          sorter: (a, b) => {
            let x = [];
            x.push(a);
            x.push(b);
            x.sort();
            console.log(x);
          },
          scopedSlots: {customRender: "catser"},
        },
      ],
    };
  },
  methods: {
    getImage(id) {
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      if (id) {
        ax.get(`${this.$session.get("baseURI")}image/${id}/full`).then(
            (response) => {
              let self = this;
              // console.log(Buffer.from(response.data, "base64"));
              const reader = new FileReader();
              reader.addEventListener(
                  "load",
                  function () {
                    // convert image file to base64 string
                    // console.log(reader.result);
                    if (self.$refs.details_image)
                      self.$refs.details_image.src = reader.result;
                  },
                  false
              );
              reader.readAsDataURL(response.data);
            }
        );
      }

    },
    checkDetails(ref, title, date) {
      console.log(ref);
      let values = {
        account: false,
        reference: ref,
      };
      this.loader = true;
      this.getMainAxios()
          .post(
              `${this.$session.get("baseURI")}/transaction/purchase/details`,
              values
          )
          .then((result) => {
            this.loader = false;
            if (
                this.checkApiRes(
                    result,
                    values,
                    "/transaction/purchase/details",
                    "post"
                )
            ) {
              console.log(result);

              // let actions = result.data.data.actions;
              let entries = result.data.data.entries;
              let items = result.data.data.items;
              let styles = result.data.data.styles;
              let finalObj = [];

              for (let i = 0; i < items.length; i++) {
                let item = items[i];
                for (let j = 0; j < item.entries.length; j++) {
                  let entry = item.entries[j];
                  for (let k = 0; k < entries.length; k++) {
                    let baseEntry = entries[k];
                    if (baseEntry.id == entry.entryId) {
                      items[i].entries[j].entry = baseEntry;
                    }
                  }
                  styles.forEach((style) => {
                    if (style.id == entry.entry.labelViewStyleId) {
                      items[i].entries[j].entry.labelViewStyle = style;
                    }
                    if (style.id == entry.entry.labelPrintStyleId) {
                      items[i].entries[j].entry.labelPrintStyle = style;
                    }
                    if (style.id == entry.entry.valueViewStyleId) {
                      items[i].entries[j].entry.valueViewStyle = style;
                    }
                    if (style.id == entry.entry.valuePrintStyleId) {
                      items[i].entries[j].entry.valuePrintStyle = style;
                    }
                  });
                }
              }
              finalObj = items;

              console.log("final", finalObj);
              finalObj.forEach(obj => {
                if (obj.imageId) {
                  this.getImage(obj.imageId);
                }
              })
              this.detailsHeader = {title: title, date: date};

              this.itemDetailsObject = finalObj;
              this.itemDetailsVisible = true;
            }
          });
    },
    handleTableChange(pagination, filters, sorter) {
      console.log({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    changeToDate(date, dateString) {
      this.to = date;
      console.log("To: ", date, dateString);
      if (this.from && this.to) {
        this.fetch();
      }
    },
    changeFromDate(date, dateString) {
      this.from = date;
      console.log("From: ", date.day(), dateString);
      if (this.from && this.to) {
        this.fetch();
      }
    },
    fetch: function () {
      this.loading = true;
      this.queryData().then((result) => {
        let values = {
          account: this.device,
          dayFrom: this.from.date(),
          monthFrom: this.from.month() + 1,
          yearFrom: this.from.year(),
          dayTo: this.to.date(),
          monthTo: this.to.month() + 1,
          yearTo: this.to.year(),
        };
        console.log(values);
        if (this.checkApiRes(result, values, "transaction/purchases", "post")) {
          console.log(result);
          const pagination = {...this.pagination};
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = result.data.data.purchases.length;
          this.loading = false;
          this.data = [];
          let i = 0;
          result.data.data.purchases.forEach((purchase) => {
            result.data.data.styles.forEach((style) => {
              if (style.id === purchase.amountStyleId) {
                let temp_date = `${new Date(purchase.date)}`.toLocaleString().split(" ");
                this.data.push({
                  description: {
                    ref: purchase.reference,
                    date: `${temp_date[0]}-${temp_date[1]}-${temp_date[2]}-${temp_date[3]}-${temp_date[4]}`,
                    dateInt: purchase.date,
                    description: `${purchase.description}`,
                  },
                  inout: {
                    date: `${temp_date[0]}-${temp_date[1]}-${temp_date[2]}-${temp_date[3]}-${temp_date[4]}`,
                    description: `${purchase.description}`,
                    ref: purchase.reference,
                    style: style,
                    amount: `${purchase.amount}`,
                    balance: `${purchase.balance}`,
                  },
                  catser: {
                    date: `${temp_date[0]}-${temp_date[1]}-${temp_date[2]}-${temp_date[3]}-${temp_date[4]}`,
                    description: `${purchase.description}`,
                    text: `${
                        purchase.category ? purchase.category : purchase.service
                    }`,
                    ref: purchase.reference,
                  },
                  key: i,
                });
                return;
              }
            });
            i++;
          });
          this.pagination = pagination;
        } else {
          this.loading = false;
        }
      });
    },
    queryData() {
      return this.getMainAxios().post(
          `${this.$session.get("baseURI")}transaction/purchases`,
          {
            account: this.device,
            dayFrom: this.from.date(),
            monthFrom: this.from.month() + 1,
            yearFrom: this.from.year(),
            dayTo: this.to.date(),
            monthTo: this.to.month() + 1,
            yearTo: this.to.year(),
          }
      );
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  mounted() {
    this.fetch();
  },
  created: function () {
    this.checkLocal();

    if (this.$route.params.data) {
      //  ok
      this.parent = this.$route.params.data;
      this.$session.set("activity", this.$route.params.data);
    } else {
      this.parent = this.$session.get("activity");
      //  undefined
    }
    console.log(this.parent);
  },
};
</script>

<style lang="scss" scoped>
.activity-mother {
  background-color: rgb(249, 249, 249) !important;

  .activity {
    .date-picker {
      background-color: white;
      padding: 20px;
    }
  }
}

.ant-table-wrapper {
  width: 100% !important;
}

.date {
  color: darkgray;
}

.align-start {
  text-align: start;
}

.align-end {
  text-align: end;
}

.voucher-image {
  img {
    width: inherit;
  }
}

.calendar {
  font-size: 30px;
  border-radius: 45px;
  background: #e62946;
  padding: 10px;
  color: white;
}

.infoButon {
  width: 100%;
}
</style>
